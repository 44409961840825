import React, { useState, useEffect } from "react";
import bg from "../assets/networkgif.webp"
import bg2 from "../assets/networkgif2.gif"
import gplay from "../assets/gplay.png"
import Header from "../components/header";
import Footer from "../components/footer";
import { Button, TextField } from "@mui/material";
import {Autocomplete} from "@mui/joy"
import {db, auth} from "../App"
import { collection, getDocs, doc, getDoc, setDoc, query, where, DocumentData, addDoc } from "firebase/firestore";
import {Auth, getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import { Search } from "@mui/icons-material";
import { width } from "@mui/system";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import lofi from "../assets/lofi.jpg"
import lofi2 from "../assets/lofi2.webp"
import lofi7 from "../assets/lofi7.jpg"
import lofi8 from "../assets/lofi8.png"
import { Helmet } from 'react-helmet';
import GoogleButton from "react-google-button";
import moment from "moment";


function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}



const Article: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [user,set_user] = useState<any>(null)
  const [profession,set_profession] = useState<any>(null)
  const location = useLocation();
  const postURL = location.pathname.split('/'); 
  console.log('URLis', postURL);
  const postID = postURL[2]
  const [post, set_post] = useState<any>([])
  const [comment, set_comment] = useState<any>("")
  const [articles, set_articles] = useState<any>([])
  const [comments, set_comments] = useState<any>("")

  const navigate = useNavigate();


  useEffect(() => {

    //fetchPost();

    let user = localStorage.getItem('user');
    let profession = localStorage.getItem('profession');

    getUser()
    getArticle()
    fetchArticles()
    console.log('posturl',postURL[2]);
    if(user != null){
      console.log('udss',JSON.parse(user));
      let usx = JSON.parse(user)

      set_user(JSON.parse(user));
      set_profession(usx.profession);
    }
    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)
    setTimeout(() => {
      getComments()
    }, 2000);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[]);

  function getUser(){

    let user = localStorage.getItem("user")

    if(user !== undefined && user !== null){
      set_user(user)
    }
    else{
      set_user("")
    }
  }


  async function getArticle(){
    
    console.log('getArticles')
    let articles: DocumentData[] = []
    const q = query(collection(db, "articles"), where("postID", "==", parseInt(postURL[2])));
    console.log('getArticlesxxxxx')

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
         console.log("dtttxxx", " => ", doc.data());
            articles.push(doc.data());
         
         
    })
    
    await set_post(articles);
    getComments()


}

async function fetchArticles(){

  let articlelist: DocumentData[] = []
     
  await getDocs(collection(db, "articles"))
      .then((querySnapshot)=>{               
          const newData = querySnapshot.docs
              .map((doc) => {
                // console.log('dxx',doc.data())
                if(articlelist.length < 5){
                  articlelist.push(doc.data())
                }
              } 
            );
      })

      set_articles(articlelist);
  }


async function gotonext(id:any){

    navigate("/article/"+id)
    window.location.reload();
}

const googleSignin = () => {
  const auth = getAuth();
  const context = this;
  
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then(async(result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential != null) {
      const token = credential.accessToken;
      }
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      console.log('signed in',user)
      localStorage.setItem('user',JSON.stringify(user))
      const userjson = JSON.parse(JSON.stringify(user))
      let validate = false;
      var rand = String(Math.floor(Math.random()*9000) + 1000);
  
      var timeNow = new Date().getTime();
      var userId =  rand + timeNow;
  
      const q = query(collection(db, "users"), where("email", "==", userjson.email));
  
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        localStorage.setItem("user", JSON.stringify(doc.data()))
        validate = true
      });
  
      if(validate === false){
        await setDoc(doc(collection(db, "users"), userjson.uid), {
            username: userjson.displayName,
            userId:userjson.uid,
            email:userjson.email,
            userpic:userjson.photoURL,
            profession:location.state.type,
            joindate:timeNow
        }).then(async ()=>{
  
          const q = query(collection(db, "users"), where("email", "==", userjson.email));
  
          const querySnapshot = await getDocs(q);
  
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            localStorage.setItem("user", JSON.stringify(doc.data()))
            validate = true
          });
          setTimeout(() => {
            window.location.reload()
        }, 2000);
        })
     }
     else{
  
      window.location.reload()
  
     }
         // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log('errorx',error)
    // ...
  });
}

async function getComments(){

  if(postID !== undefined){
  
    console.log("POST is....")
  let comments: DocumentData[] = []
     
  await getDocs(collection(db, "comments/"+postID+"/comments"))
      .then((querySnapshot)=>{               
          const newData = querySnapshot.docs
              .map((doc) => {
                console.log('allcomments',doc.data())
                comments.push(doc.data())
              } 
            );
      })

      set_comments(comments);
    }
    else{
      console.log("POST isnull")
    }
  
}


async function addComment(){
  
  console.log('adding comment')
if(comment !== ""){

  
  await addDoc((collection(db, "comments/"+post[0].postID+"/comments")), {
      comment:comment,
      userId:user.userId,
      username:user.username,
      userpic:user.userpic,
      timestamp: Date.now()
  }).then(()=>{
    console.log('comment added')
  })
}

  set_comment("")

  getComments()
}




if(post.length > 0){
 if(window.innerWidth > 650){
  return (

    <div style={{margin:0,  flexDirection:'column', backgroundColor:'white', height:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:200, paddingRight:200,}} >
    <Helmet>
      <title>{post[0].title}</title>
      <meta property="og:title" content={post[0].title} />
      <meta property="og:description" content={post[0].para1} />
      <meta property="og:image" content={post[0].image} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
    </Helmet>
        <Header/>
        {/* <img src={logo} style={{height:40 ,borderRadius:20, margin:20, marginBottom:50}}/> */}

        {/* <p style={{marginTop:-40, marginBottom:40, fontStyle:'italic', color:'white'}}>Aap kya karte hain ?</p> */}

        <div style={{display:'flex', marginTop:80,flexDirection:'column', width:'100%', justifyContent:'flex-start', alignItems:'center'}}>

        {/* <div style={{position: 'relative', zIndex: 1, width:windowSize.innerWidth}}>
            <img src={post[0].image1} style={{width:windowSize.innerWidth, position:'absolute', zIndex:2}}/>
            <span  style={{position: 'relative', top: '-10px',zIndex: 3}}>OVERLAY</span>
        </div> */}

            <div style={{width:windowSize.innerWidth, backgroundColor:"#dadada20",zIndex:2}}>
            <img src={post[0].image1} style={{width:windowSize.innerWidth, zIndex:1}}/>
            <h1 style={{zIndex:3, fontSize:70, position:'absolute', bottom:100, left:100, color:'white'}}>{post[0].title}</h1>
            </div>
            <div style={{marginTop:20}}>
            <p>{post[0].para1}</p>
            <p><b>{post[0].title2}</b></p>
            <p>{post[0].para2}</p>
            <h3 style={{backgroundColor:"#dadada"}}>"{post[0].quote}"</h3>
            <p>{post[0].para3}</p>
            <p><b>{post[0].title3}</b></p>
            <p>{post[0].para4}</p>
            <p>{post[0].para5}</p>
            <p>{post[0].para6}</p>

            </div>

        </div>
        <p style={{alignSelf:'flex-start', marginTop:20}}><b>Up Next</b></p>

          <div style={{display:'flex',flexDirection:'row', maxWidth:windowSize.innerWidth, width:'100%', marginLeft:50,marginRight:50}}>
          {articles.map((val:any, index:any) => {
              return (<div onClick={()=>gotonext(val.postID)} style={{backgroundColor:'white',width:'90%', margin:20, display:'flex',flexDirection:'column'}}>
                <img style={{height:100}} src={val.image1}/>
                <h4 style={{textAlign:'center', fontSize:14, padding:20}}>{val.title}</h4>
                </div>)
            }
          )}

        </div>
        {comments.length > 0?
        <div style={{display:'flex',flexDirection:'column', maxWidth:windowSize.innerWidth, width:'100%', marginLeft:50,marginRight:50}}>
          {comments.map((val:any, index:any) => {
              return (<div style={{backgroundColor:'white',width:'90%', margin:20, display:'flex',flexDirection:'row', justifyContent:'flex-start',alignItems:'flex-start'}}>
                <img style={{height:50, borderRadius:10}} src={val.userpic}/>
                <h4 style={{textAlign:'center', fontSize:14, padding:20, color:'black'}}>{val.comment}</h4>
                <p style={{textAlign:'center', fontSize:10, padding:20, color:'black'}}>{moment(val.timestamp).format("MM/DD/YYYY HH:mm")}</p>

                </div>)
            }
          )}
        </div>
        :
        null}

      {user !== ""
      ?
      <div style={{display:'flex',flexDirection:'column', height:150, width:'100%',justifyContent:'flex-start',alignItems:'flex-start'}}>
      <TextField 
        sx={{ "& .MuiInputBase-input": { fontSize: 12, height: 100, padding: 1, width:500 } }}
        placeholder="Leave a comment." onChange={(event)=>set_comment(event.target.value)} style={{width:400, maxWidth:400,height:200, backgroundColor:'white', borderRadius:2}} />
        <div style={{flexDirection:'row',display:'flex', justifyContent:'flex-start',alignItems:'flex-start', width:'100%'}}>
          <Button onClick={()=>addComment()} variant="contained" style={{textAlign:'center', margin:5, padding:10, backgroundColor:"black",color:'white', height:40, width:'20%', borderRadius:5, fontSize:12}}>Post</Button>
        </div>
      </div>
      :
      <div style={{display:'flex',flexDirection:'column', height:150, width:'100%',justifyContent:'flex-start',alignItems:'flex-start'}}>

      <div style={{marginTop:60, paddingBottom:40}}>
        <p style={{marginTop:-20}}>Sign in with Google to post a comment.</p>
        <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', margin:5, padding:10, backgroundColor:"black",color:'white', height:40, width:'20%', borderRadius:5, fontSize:12}}>Post</Button>
      </div>        
      </div>
      } 
        
      <div>
    
    </div>
    </div>

    

);


 }
 else{

  return (



    <div style={{margin:0,backgroundPosition:'center',maxWidth:'100%', width:'100%',  flexDirection:'column', backgroundColor:'white', height:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:2, paddingRight:2}} >
    <Helmet>
      <title>{post[0].title}</title>
      <meta property="og:title" content={post[0].title} />
      <meta property="og:description" content={post[0].para1} />
      <meta property="og:image" content={post[0].image} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
    </Helmet>
        <Header/>
        <div style={{display:'flex', marginTop:0,flexDirection:'column', width:'100%', justifyContent:'flex-start', alignItems:'center'}}>

        {/* <div style={{position: 'relative', zIndex: 1, width:windowSize.innerWidth}}>
            <img src={post[0].image1} style={{width:windowSize.innerWidth, position:'absolute', zIndex:2}}/>
            <span  style={{position: 'relative', top: '-10px',zIndex: 3}}>OVERLAY</span>
        </div> */}

            <div style={{width:windowSize.innerWidth, backgroundColor:"#dadada20",zIndex:2}}>
            <img src={post[0].image1} style={{width:windowSize.innerWidth, zIndex:1}}/>
            <h1 style={{zIndex:3, fontSize:20, position:'absolute', top:100, left:20, color:'white'}}>{post[0].title}</h1>
            </div>
            <div style={{marginTop:20,padding:40}}>
            <p>{post[0].para1}</p>
            <p><b>{post[0].title2}</b></p>
            <p>{post[0].para2}</p>
            <h3 style={{backgroundColor:"#dadada"}}>"{post[0].quote}"</h3>
            <p>{post[0].para3}</p>
            <p><b>{post[0].title3}</b></p>
            <p>{post[0].para4}</p>
            </div>

        </div>

        <p style={{alignSelf:'flex-start', marginTop:20, width:'100%'}}><b>Up Next</b></p>

          <div style={{display:'flex',flexDirection:'row', maxWidth:windowSize.innerWidth, width:'100%', marginLeft:50,marginRight:50}}>
          {articles.map((val:any, index:any) => {
            if(index < 2){
              return (<div onClick={()=>gotonext(val.postID)} style={{backgroundColor:'white',width:'90%', margin:5, display:'flex',flexDirection:'column'}}>
                <img style={{height:80}} src={val.image1}/>
                <h4 style={{textAlign:'center', fontSize:14, padding:20}}>{val.title}</h4>
                </div>)
            }
          }
          )}

        </div>

        {/* <div style={{display:'flex',flexDirection:'row', maxWidth:windowSize.innerWidth, width:'100%', marginLeft:50,marginRight:50}}>
          {comments.map((val:any, index:any) => {
            if(index < 2){
              return (<div onClick={()=>gotonext(val.postID)} style={{backgroundColor:'white',width:'90%', margin:5, display:'flex',flexDirection:'column'}}>
                <img style={{height:80}} src={val.userpic}/>
                <h4 style={{textAlign:'center', fontSize:14, padding:20}}>{val.title}</h4>
                </div>)
            }
          }
          )}

        </div> */}

        {user !== ""
      ?
      <div style={{display:'flex',flexDirection:'column', height:150, width:'100%',justifyContent:'flex-start',alignItems:'flex-start'}}>
      <TextField 
        sx={{ "& .MuiInputBase-input": { fontSize: 12, height: 100, padding: 1, width:500 } }}
        placeholder="Leave a comment." onChange={(event)=>set_comment(event.target.value)} style={{width:400, maxWidth:400,height:200, backgroundColor:'white', borderRadius:2}} />
        <div style={{flexDirection:'row',display:'flex', justifyContent:'flex-start',alignItems:'flex-start', width:'100%'}}>
          <Button onClick={()=>addComment()} variant="contained" style={{textAlign:'center', margin:5, padding:10, backgroundColor:"black",color:'white', height:40, width:'20%', borderRadius:5, fontSize:12}}>Post</Button>
        </div>
      </div>
      :
      <div style={{display:'flex',flexDirection:'column', height:150, width:'100%',justifyContent:'flex-start',alignItems:'flex-start'}}>

      <div style={{marginTop:60, paddingBottom:40}}>
        <p style={{marginTop:-20}}>Sign in with Google to post a comment.</p>
        <GoogleButton onClick={()=>navigate("/signup")}  style={{paddingLeft:20, width:290, marginLeft:5, paddingRight:20, color:'black', height:50, fontStyle:'bold', fontWeight:400, fontSize:16, backgroundColor:"white"}}>Sign In With Google to Post a comment</GoogleButton>
      </div>        
      </div>
      } 
        
    </div>


);
 }
}
else{
  return null;
}

  
};

export default Article;
