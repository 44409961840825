import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import logo from "../assets/logo.png";
import jobs from "../utils/jobposts.json"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const Jobs: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [email, set_search] = useState<string>("")
  const [user_option, set_user_option] = useState<string>("")
  const navigate = useNavigate()

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


  const setOption=(item:any)=>{
    localStorage.setItem('profession',item)
    set_user_option(item)
    navigate("/signup")
  }

 if(window.innerWidth > 650){
  return (


    <div style={{margin:0,  flexDirection:'column', overflow:'scroll', backgroundColor:'black', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:100, paddingBottom:200}} >

        <img src={logo} style={{height:60 ,borderRadius:20, marginBottom:50}}/>

        <p style={{marginTop:20, marginBottom:40, fontStyle:'italic', color:'white'}}>Available Jobs</p>

        <div>
        <Container>
        <Row style={{display:'flex', margin:0,flexDirection:"column",justifyContent:"center", backgroundColor:'red',alignItems:'center', width:'100%'}}>
        {jobs.map((item:any,index)=>{
          return(
            <Col xs={12} sm={12} md={12} lg={12}>
              <Button onClick={(()=>setOption(item.id))} style={{backgroundColor:'#ff914d',width:800,height:'80%', display:'flex',flexDirection:'column', color:'white', marginTop:5,marginBottom:5, fontSize:18, fontWeight:800, textAlign:'center', borderRadius:5, padding:50}}>
                <h4 style={{textAlign:'left'}}>{item.job}</h4>
                <p style={{textAlign:'left', marginTop:10, fontSize:12}}>{item.description}</p>
                </Button>
              </Col>

          )
        })}
        </Row>
        </Container>
        </div>

     </div>
    

);


 }
 else{

  return (


    <div style={{margin:0,maxWidth:windowSize.innerWidth, width:windowSize.innerWidth,  flexDirection:'column', backgroundColor:'black', zIndex:99999, height:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center',paddingTop:40}} >

        <img src={logo} style={{height:40 ,borderRadius:20, margin:20, marginBottom:50}}/>

        <p style={{ marginBottom:40, fontStyle:'italic', color:'white'}}>Aap kya karte hain ?</p>

        {/* <TextField placeholder="Search for skillsets...." onChange={(event)=>set_search(event.target.value)} style={{width:500, maxWidth:500, backgroundColor:'white', borderRadius:2}} /> */}

        {/* <p style={{ color:'white', marginTop:50}}>Select Your Profession</p> */}
        <div>
        {jobs.map((item:any,index)=>{
          return(
            // <Col style={{backgroundColor:'orange', color:'white', margin:10, borderRadius:5, padding:40}} xs={10} sm={10} md={6} lg={3}>
            //   <div >
            //     {item.category}
            //     </div>
            //   </Col>
            <Button onClick={(()=>setOption(item.category))} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"#ff941d",color:'white', height:80, width:'90%', borderRadius:5, margin:5}}>{item.category}</Button>


          )
        })}
        </div>
        
     </div>

);
 }

  
};

export default Jobs;
