import React, { useState, useEffect } from "react";
import { Button, TextField, Autocomplete } from "@mui/material";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import lofi8 from "../assets/lofi8.png"
import * as nsfwjs from "nsfwjs";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../App";


function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}



const Upload: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [user,set_user] = useState<any>(null)
  const [profession,set_profession] = useState<any>(null)
  const [description,set_description] = useState<any>("")
  const [image, set_image] = useState<any>(null)
  const [isImgSelected, set_isImgSelected] = useState<any>(false)

  const navigate = useNavigate();


  useEffect(() => {

    //fetchPost();

    let user = localStorage.getItem('user');
    let profession = localStorage.getItem('profession');

    if(user != null){
      console.log('udss',JSON.parse(user));
      let usx = JSON.parse(user)

      set_user(JSON.parse(user));
      set_profession(usx.profession);
    }
    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[]);

  const onImageChange = (event:any) => {
    if (event.target.files && event.target.files[0]) {
      set_image(event.target.files[0]);
      set_isImgSelected(true)
    }
   }


   async function cancelFile(){
    set_isImgSelected(false)
    set_image(null)
   }

   async function uploadPost(){

    // const img = document.getElementById("img");

    // // If you want to host models on your own or use different model from the ones available, see the section "Host your own model".
    // const model = await nsfwjs.load();

    // // Classify the image
    // const predictions = await model.classify(image);
    // console.log("Predictions: ", predictions);
    var timeNow = new Date().getTime();
    var rand = String(Math.floor(Math.random()*9000) + 1000);


    if(description.length > 8){

        await setDoc(doc(collection(db, "posts"), timeNow+rand), {
          username: user.username,
          userId:user.userId,
          userpic:user.userpic,
          profession:user.profession,
          skills:user.skills,
          bio:user.bio,
          location:user.location,
          postdate:timeNow,
          description:description,
          image:"",
          likes:0,
          comments:0,
      }).then(async ()=>{

        setTimeout(() => {
          navigate("/profile")
      }, 2000);

      })
    }
    else{
      alert('Your note should be more than 8 characters.')
      return;
    }

   }


if(user!= null){
 if(window.innerWidth > 650){
  return (


    <div style={{margin:0,  backgroundImage:"url("+lofi8+")",backgroundSize:"cover",  flexDirection:'column', backgroundColor:'#35063e', zIndex:99999, minHeight:'100vh', backgroundRepeat:'repeat', paddingBottom:100, display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:200, paddingRight:200,paddingTop:40}} >

        <img src={logo} style={{height:40 ,borderRadius:20, margin:20, marginBottom:50}}/>

        <div style={{display:'flex',backgroundColor:"#280137", flexDirection:'column', marginTop:20, height:500, width:'60%', justifyContent:'center',borderRadius:10, alignItems:'center'}}>

                {
                // <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                //   {/* <p style={{fontSize:28,backgroundColor:"black",color:'white',padding:50,borderRadius:40}}>{(user.username).substring(0, 1)}</p> */}
                //   <label style={{color:'white'}}>
                //   <input accept="image/*" style={{display:"none"}} type="file" onChange={onImageChange} className="filetype" />
                //   Select Image (Optional)
                //   </label>
                // </div>
                // :
                // <div style={{margin:5, display:'flex',flexDirection:'column'}}>

                //   <img alt="preview image" src={URL.createObjectURL(image)} style={{height:200,width:200}} />
                //   <Button variant='outlined' onClick={()=>cancelFile()} style={{textAlign:'center',color:'white', marginTop:40, height:40, width:200, borderRadius:20}}>Change</Button>

                // </div>
                }
                <div style={{margin:5}}>
                <TextField inputProps={{ style: { padding: 40 } }} multiline={true} placeholder="Post a note." onChange={(event)=>set_description(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', marginTop:20,borderRadius:2, borderColor:"transparent"}} />
                </div>

                <Button onClick={uploadPost} variant='contained' style={{textAlign:'center', marginTop:40, backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Post It</Button>

        </div>

        <div>
    
    </div>
    </div>

    

);


 }
 else{

  return (



    <div style={{margin:0,backgroundImage:"url("+lofi8+")",backgroundSize:"cover",backgroundPosition:'center',maxWidth:'100%', width:'100%',  flexDirection:'column', backgroundColor:'white', zIndex:99999, height:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:2, paddingRight:2,paddingTop:40}} >

        <img src={logo} style={{height:40 ,borderRadius:20, margin:20, marginBottom:50}}/>


        <div style={{display:'flex',flexDirection:'row',maxWidth:windowSize.innerWidth, height:200, width:'90%', justifyContent:'center',borderRadius:50, alignItems:'center'}}>

            <div style={{flex:1,borderBottomLeftRadius:10,borderTopLeftRadius:10, backgroundColor:"#280137", justifyContent:'center',alignItems:'center', display:'flex', height:'100%', margin:0}}>

              {user != null?
              <img src={user.photoURL?user.photoURL:null} style={{height:90,width:90}}/>
              :
              null}
              

            </div>

            <div style={{flex:1,borderBottomRightRadius:10,borderTopRightRadius:10, flexDirection:'column', backgroundColor:"#280137", height:'100%', margin:0,justifyContent:'center',alignItems:'center', display:'flex',}}>

                <h3 style={{color:"white"}}>{user.username}</h3>

                <h2 style={{textAlign:'center', color:"white", fontSize:12}}>{profession}</h2>

                {user.profile === undefined
                ?
                <Button variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:40, width:'80%', borderRadius:20, fontSize:10}}>Create Profile</Button>
                :
                <p>Profile</p>
                }

            </div>
            

        </div>


        <div style={{display:'flex',backgroundColor:"#280137", flexDirection:'column', marginTop:40, height:300, width:'90%', justifyContent:'center',borderRadius:10, alignItems:'center'}}>


                <p style={{color:"white"}}>You have not uploaded anything yet. </p>

                <div style={{margin:10}}>
                <Button variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Post A Lookout</Button>

                </div>

        </div>
        <div>
    

    <div style={{display:'flex',flexDirection: 'column',justifyContent:'center',alignItems:'center'}}>

    <div style={{marginTop:60}}>
    </div>
    </div>
    </div>
    </div>


);
 }
}
else{
  return null;
}

  
};

export default Upload;
