import React, { useState, useEffect } from "react";
import bg from "../assets/networkgif.webp"
import bg2 from "../assets/networkgif2.gif"
import gplay from "../assets/gplay.png"
import Header from "../components/header";
import Footer from "../components/footer";
import hobbies from "../utils/hobbies.json"
import { Button, TextField } from "@mui/material";
import {Autocomplete} from "@mui/joy"
import {db, auth} from "../App"
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { Search } from "@mui/icons-material";

import { getAuth, signInWithEmailAndPassword,setPersistence,browserSessionPersistence} from "firebase/auth";
import { useNavigate } from "react-router-dom";



function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const Login: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [move_1, set_next] = useState<boolean>(false)
  const [username, set_username] = useState<string>("")
  const [email, set_email] = useState<string>("")
  const [password, set_password] = useState<string>("")
  const [is_popup,set_is_popup] = useState<boolean>(false) //TBA in edit mode
  const [popup_message, set_popup_message] = useState<string>("");

  const navigate = useNavigate();



  useEffect(() => {
    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

const fetchPost = async () => {
       
    await getDocs(collection(db, "users"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            console.log('dxx',querySnapshot.docs)
        })
   
}

function validateEmail(email:any) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function hasWhiteSpace(s:any) {

  if (/\s/.test(s)) {
    return true
  }
  else{

    return false
  }

}

function password_validator(input:string){


  let regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$")
  
  let test = regex.test(input);

  if(test === true){

    if (hasWhiteSpace(input)=== true){

      return false;
    }
    else{
      return true;
    }

  }
  else{

    return test;
  }

}


const signIn = async()=>{

    if(validateEmail(email) === false){

      set_is_popup(true)
      set_popup_message("Please enter a valid email.")

      setTimeout(() => {

        set_is_popup(false)
        set_popup_message("Error.")

        
      }, 2000);

  }
  else if(password.length < 1){

      set_is_popup(true)
      set_popup_message("Please enter your password.")

      setTimeout(() => {

        set_is_popup(false)
        set_popup_message("Error.")

        
      }, 2000);

  }
  else if(hasWhiteSpace(email) === true|| hasWhiteSpace(password) === true){

      set_is_popup(true)
      set_popup_message("No blank spaces allowed.")

      setTimeout(() => {

        set_is_popup(false)
        set_popup_message("Error.")

        
      }, 2000);

  }

  setPersistence(auth, browserSessionPersistence).then(()=>{
  signInWithEmailAndPassword(auth, email, password)
  .then(async (userCredential) => {
    // Signed in 
    const user = userCredential.user;
    console.log('user',userCredential)

    const coll = collection(db,"users")
    const q = query(coll, where("email", "==", email));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      localStorage.setItem("userCred",JSON.stringify(userCredential.user))
      localStorage.setItem("user",JSON.stringify(doc.data()))

      setTimeout(() => {

        set_is_popup(true)
        set_popup_message("Logged in...")
    
        setTimeout(() => {
    
          set_is_popup(false)
          set_popup_message("Error.")
          navigate("/dashboard")
          
        }, 1000);
        
      }, 1000);

    })


    // ...
  })
  .catch((error) => {
    console.log('ex',error)
    const errorCode = error.code;
    const errorMessage = error.message;
    set_is_popup(true)
    set_popup_message("The email/password didn't match.")

    setTimeout(() => {

      
      set_is_popup(false)
      set_popup_message("Error.")

      
    }, 2000);
  });

})


}


 if(window.innerWidth > 650){

  return(
  <div style={{margin:0, padding:0, flexDirection:'column', backgroundImage:`url(${bg2})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundColor:'black', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}} >
    
  <Header/>

  <div style={{display:'flex', height:'100vh',width:'100vw', flexDirection:'column', backgroundColor:"#00000088",justifyContent:'center', alignItems:'center'}}>
  
  <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
  <h1 style={{fontSize:56, fontFamily:'Montserrat', fontWeight:700, position:'absolute', top:100, textAlign:'center'}}>Area</h1>
  <h5 style={{color:'white', fontFamily:"Montserrat", fontSize:18, position:'absolute', top:180,}}>Login</h5>
  </div>  
  
  
  <div style={{marginTop:-80,
  transition: "all .2s", justifyContent:'center',alignItems:'center', 
  minHeight:400, flexDirection:'column',display:'flex',
  position:'absolute',top:'40%'}}>
  <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10}}>Username or Email</p>
  <TextField placeholder="Your Email" onChange={(event)=>set_email(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:2}} />
  <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10}}>Password</p>
  <TextField placeholder="Your Password" onChange={(event)=>set_password(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:2}} />
  
  <div style={{marginTop:60}}>
  <Button variant="contained" onClick={()=>set_next(!move_1)}  style={{paddingLeft:60, marginLeft:5, paddingRight:60, fontStyle:'bold', fontWeight:400, fontSize:14, backgroundColor:"#00bfff",fontFamily:"Montserrat"}}>Login</Button>
  </div>
  </div>

  
  </div>
   </div>


 
  )
 }
 else{

  return (


    <div style={{margin:0, padding:0, flexDirection:'column', backgroundImage:`url(${bg2})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundColor:'black', height:'100vh', maxWidth:window.innerWidth, width:window.innerWidth, minWidth:window.innerWidth, display:'flex', justifyContent:'center', alignItems:'center'}} >
    
    <Header/>

    <div style={{display:'flex', height:'100vh',width:window.innerWidth, flexDirection:'column', backgroundColor:"#00000088",justifyContent:'center', alignItems:'center'}}>
    
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
    <h1 style={{fontSize:32, fontFamily:'Satisfy', fontWeight:700, position:'absolute', top:100, textAlign:'center'}}>Area</h1>
    <h5 style={{color:'white', fontFamily:"Montserrat", fontSize:18, position:'absolute', top:180,}}>Login</h5>
    </div>  
    
    <div style={{marginTop:-80,
    transition: "all .2s", justifyContent:'center',alignItems:'center', width:'95%', maxWidth:'95%',
    minHeight:400, flexDirection:'column',display:'flex',
    position:'absolute',top:'40%'}}>
    <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10,marginLeft:'5%'}}>Email</p>
    <TextField placeholder="Your Email" onChange={(event)=>set_email(event.target.value)} style={{width:'90%', maxWidth:'90%', backgroundColor:'white', borderRadius:2}} />
  <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10, marginLeft:'5%'}}>Password</p>
  <TextField placeholder="Your Password" onChange={(event)=>set_password(event.target.value)} style={{width:'90%', maxWidth:'90%',  backgroundColor:'white', borderRadius:2}} />
  
    <div style={{marginTop:60}}>
    <Button variant="contained" onClick={()=>signIn()}  style={{paddingLeft:60, marginLeft:5, paddingRight:60, fontStyle:'bold', fontWeight:700, fontSize:14, backgroundColor:"#00bfff"}}>Login</Button>
    </div>
    <div style={{marginTop:10}}>
    <Button variant="text" onClick={()=>navigate("/forgot")}  style={{paddingLeft:60, marginLeft:5, paddingRight:60, fontStyle:'bold', fontWeight:700, fontSize:10, color:'white', textTransform:'none'}}>Forgot Password ?</Button>
    </div>
    <div style={{marginTop:60}}>
    <Button variant="text" onClick={()=>navigate("/register")}  style={{paddingLeft:60, marginLeft:5, paddingRight:60, fontStyle:'bold', fontWeight:700, fontSize:14, color:'white', textTransform:'none'}}>Create Your Account</Button>
    </div>
    </div>

    
    </div>
    {is_popup === true ?
        
        <div id="popupBox" style={{textAlign:'center', color:'black'}}>

          <p><b>{popup_message}</b></p>

        </div>
        :
        null
        }
     </div>
    

);
 }

  
};

export default Login;
