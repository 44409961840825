import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, DocumentData, getDocs } from "firebase/firestore";
import { db } from "../App";
import Header from "../components/header";
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const People: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [email, set_search] = useState<string>("")
  const [people, set_people] = useState<any>([])
  const [user_option, set_user_option] = useState<string>("")
  const navigate = useNavigate()

  useEffect(() => {

    fetchPeople()
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[]);


  const setOption=(item:any)=>{
    localStorage.setItem('profession',item)
    set_user_option(item)
    navigate("/signup")
  }

  async function fetchPeople(){

    let articlelist: DocumentData[] = []
       
    await getDocs(collection(db, "users"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => {
                  console.log('dxx',doc.data())
                  articlelist.push(doc.data())
                } 
              );
        })

        let finalart = shuffle(articlelist)

        set_people(finalart);

   
}

function shuffle(arrParam: any[]): any[]{
  let arr = arrParam.slice(),
      length = arr.length,
      temp,
      i;

  while(length){
      i = Math.floor(Math.random() * length--);

      temp = arr[length];
      arr[length] = arr[i];
      arr[i] = temp;
  }

  return arr;
}


 if(window.innerWidth > 650){
  return (


    <div style={{margin:0,  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:10, paddingLeft:200, paddingRight:200,paddingBottom:200}} >
          <Helmet>
            <title>KAAMKA.COM - The No.1 Source for interesting articles, content, visuals and stories.</title>
            <meta property="og:title" content="The No.1 Source for interesting articles, content, visuals and stories." />
            <meta property="og:description" content="KAAMKA.com - The No.1 Source for interesting articles, content, visuals and stories." />
            <meta property="og:image" content="https://kaamka.com/static/media/logo.181195da48df830a8e09.png" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
          </Helmet>
        <Header/>

        <div style={{marginTop: 100}}>

        <div style={{justifyContent:'center',alignItems:'center', display:'flex'}}>

        <div style={{justifyContent:'center',alignItems:"center", alignSelf:'center', alignContent:'center', flexDirection:'row', display:'flex' }}>

            <Button variant="contained" style={{backgroundColor:'#dadada80', color:'black',margin:5,width:160, borderRadius:10, padding:30,paddingLeft:20,paddingRight:20}}>Musicians</Button>
            <Button variant="contained" style={{backgroundColor:'#dadada80',margin:5, borderRadius:10,width:160, color:'black', padding:30,paddingLeft:20,paddingRight:20}}>Artists</Button>
            <Button variant="contained" style={{backgroundColor:'#dadada80',margin:5, borderRadius:10,width:160, color:'black', padding:30,paddingLeft:20,paddingRight:20}}>Writers</Button>
            <Button variant="contained" style={{backgroundColor:'#dadada80',margin:5, borderRadius:10,width:160, color:'black', padding:30,paddingLeft:20,paddingRight:20}}>Programmers</Button>
            <Button variant="contained" style={{backgroundColor:'#dadada80',margin:5, borderRadius:10,width:160, color:'black', padding:30,paddingLeft:20,paddingRight:20}}>Fitness</Button>

          </div>

        </div>

        <div style={{ display: "grid", marginTop:100, margin:40, gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>

          {people.map((val:any, index:any) => {
              return (<div onClick={()=>navigate("/viewprofile/"+val.userId)} style={{backgroundColor:'white',height:'100%', display:'flex',flexDirection:'column', borderStyle:'solid',borderWidth:1, borderColor:"#dadada",borderRadius:5,padding:40}}>
                <img style={{height:200}} src={val.userpic}/>
                <h4 style={{textAlign:'center', fontSize:20, padding:5, color:'black'}}><b>{val.username}</b></h4>
                <h4 style={{textAlign:'center', fontSize:14, padding:5, color:'black'}}><b>{val.profession}</b></h4>
                </div>)
            }
          )}

        </div>
        </div>

     </div>
    

);


 }
 else{

  return (


    <div style={{margin:0,  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingLeft:20, paddingRight:20,paddingBottom:200, maxWidth:windowSize.innerWidth}} >

    <Header/>


    <div style={{marginTop: 100}}>

    <div style={{margin:10,marginTop:25 }}>

          {people.map((val:any, index:any) => {
              return (<div onClick={()=>navigate("/viewprofile/"+val.userId)} style={{backgroundColor:'white',width:'90%', margin:20, display:'flex',flexDirection:'column'}}>
                <img style={{height:200}} src={val.userpic}/>
                <h4 style={{textAlign:'center', fontSize:20, padding:20}}>{val.username}</h4>
                </div>)
            }
          )}

        </div>
    </div>

 </div>

);
 }

  
};

export default People;
