import React, { useState, useEffect } from "react";
import { Button, Card, Grid, TextField } from "@mui/material";
import logo from "../assets/logo.png";
import jobs from "../utils/jobs.json"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation, useNavigate } from "react-router-dom";
import img1 from "../assets/1.jpg"
import img2 from "../assets/2.jpeg"
import img3 from "../assets/3.jpg"
import img4 from "../assets/4.jpg"
import img5 from "../assets/5.jpg"
import lofi from "../assets/lofi.jpg"
import lofi2 from "../assets/lofi2.jpg"
import lofi3 from "../assets/lofi1.png"
import lofi9 from "../assets/lofi9.webp"
import { collection, DocumentData, getDocs, query, where } from "firebase/firestore";
import { db } from "../App";
import Header from "../components/header";

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const ArticleListing: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [email, set_search] = useState<string>("")
  const [articles, set_articles] = useState<any>([])
  const location = useLocation();
  const [user_option, set_user_option] = useState<string>("")
  const navigate = useNavigate()
  const postURL = location.pathname.split('/'); 
  const [post, set_post] = useState<any>([])

  useEffect(() => {

    fetchArticles()
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[]);


  const setOption=(item:any)=>{
    localStorage.setItem('profession',item)
    set_user_option(item)
    navigate("/signup")
  }

  function capitalizeFirstLetter(string:any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
    }

  async function fetchArticles(){
    
    console.log('getArticlesXXXXX' + postURL[2])
    let articles: DocumentData[] = []
    const q = query(collection(db, "articles"), where("category", "==", (capitalizeFirstLetter(postURL[2]))));
    console.log('getArticlesxxxxx')

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
         console.log("dtttxxx", " => ", doc.data());
            articles.push(doc.data());
         
    })
    
    console.log('ART LENGTH' , articles.length);
    set_articles(articles);

   
}

async function gotonext(id:any){

  navigate("/articles/"+id)
  window.location.reload();
}


 if(window.innerWidth > 650){
  return (


    <div style={{margin:0, backgroundImage:"url("+lofi+")",  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:10, paddingLeft:200, paddingRight:200,paddingBottom:200}} >
        <Header/>



        <div style={{display:'flex', borderRadius:10,flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:100, height:105, width:'100%'}}>

          <Button onClick={()=>gotonext("humor")} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5,margin:5}}>Humor</Button>
          <Button onClick={()=>gotonext("movies")} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5, margin:5}}>Movies</Button>
          <Button onClick={()=>gotonext("sports")} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5, margin:5}}>Sports</Button>
          <Button onClick={()=>gotonext("tech")} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5, margin:5}}>Technology</Button>
          <Button onClick={()=>gotonext("music")} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5, margin:5}}>Music</Button>

        </div>

        <div style={{ display: "grid", margin:40, gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>

          {articles.map((val:any, index:any) => {
              return (<div onClick={()=>navigate("/article/"+val.postID)} style={{backgroundColor:'white',height:'100%', display:'flex',flexDirection:'column'}}>
                <img style={{height:200}} src={val.image1}/>
                <h4 style={{textAlign:'center', fontSize:20, padding:20}}>{val.title}</h4>
                </div>)
            }
          )}

        </div>


     </div>
    

);


 }
 else{

  return (


    <div style={{margin:0, backgroundImage:"url("+lofi+")",  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:20, paddingRight:20,paddingBottom:200, maxWidth:windowSize.innerWidth}} >
        <Header/>

        <div style={{display:'flex', borderRadius:10,flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:95, width:windowSize.innerWidth-10, paddingLeft:40,paddingRight:40}}>
          <Button onClick={()=>navigate("/articles/work",{state:{type:"Musician"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'70%', borderRadius:5,margin:5}}>Work</Button>
          <Button onClick={()=>navigate("/articles/humor",{state:{type:"Musician"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'70%', borderRadius:5,margin:5}}>Humor</Button>
          {/* <Button onClick={()=>navigate("/articles/movies",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'50%', borderRadius:5, margin:5}}>Movies</Button> */}
          <Button onClick={()=>navigate("/articles/sports",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'70%', borderRadius:5, margin:5}}>Sports</Button>
          <Button onClick={()=>navigate("/articles/technology",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'70%', borderRadius:5, margin:5}}>Tech</Button>
          <Button onClick={()=>navigate("/articles/music",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'70%', borderRadius:5, margin:5}}>Music</Button>

        </div>
    {/* <img src={logo} style={{height:110 ,borderRadius:20, margin:20, marginBottom:40}}/> */}

    {/* <p style={{marginTop:-40, marginBottom:40, fontStyle:'italic', color:'black'}}>Aap kya karte hain ?</p> */}

    <div style={{margin:10,marginTop:40 }}>

          {articles.map((val:any, index:any) => {
              return (<div onClick={()=>navigate("/article/"+val.postID)} style={{backgroundColor:'white',width:'90%', margin:20, display:'flex',flexDirection:'column'}}>
                <img style={{height:200}} src={val.image1}/>
                <h4 style={{textAlign:'center', fontSize:20, padding:20}}>{val.title}</h4>
                </div>)
            }
          )}

        </div>

 </div>

);
 }

  
};

export default ArticleListing;
