import React, { useState, useEffect } from "react";
import { Button, Card, Grid, TextField } from "@mui/material";
import logo from "../assets/logo.png";
import jobs from "../utils/jobs.json"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import img1 from "../assets/1.jpg"
import img2 from "../assets/2.jpeg"
import img3 from "../assets/3.jpg"
import img4 from "../assets/4.jpg"
import img5 from "../assets/5.jpg"
import lofi from "../assets/lofi.jpg"
import lofi2 from "../assets/lofi2.jpg"
import lofi3 from "../assets/lofi1.png"
import lofi9 from "../assets/lofi9.webp"
import { collection, DocumentData, getDocs } from "firebase/firestore";
import { db } from "../App";
import Header from "../components/header";
import { Helmet } from "react-helmet";

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const Home: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [email, set_search] = useState<string>("")
  const [articles, set_articles] = useState<any>([])
  const [user_option, set_user_option] = useState<string>("")
  const navigate = useNavigate()

  useEffect(() => {

    fetchArticles()
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[]);


  const setOption=(item:any)=>{
    localStorage.setItem('profession',item)
    set_user_option(item)
    navigate("/signup")
  }

  async function fetchArticles(){

    let articlelist: DocumentData[] = []
       
    await getDocs(collection(db, "articles"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => {
                  console.log('dxx',doc.data())
                  articlelist.push(doc.data())
                } 
              );
        })

        let finalart = shuffle(articlelist)

        set_articles(finalart);

   
}

function shuffle(arrParam: any[]): any[]{
  let arr = arrParam.slice(),
      length = arr.length,
      temp,
      i;

  while(length){
      i = Math.floor(Math.random() * length--);

      temp = arr[length];
      arr[length] = arr[i];
      arr[i] = temp;
  }

  return arr;
}


 if(window.innerWidth > 650){
  return (


    <div style={{margin:0,  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:10, paddingLeft:200, paddingRight:200,paddingBottom:200}} >
          <Helmet>
            <title>KAAMKA.COM - The No.1 Source for interesting articles, content, visuals and stories.</title>
            <meta property="og:title" content="The No.1 Source for interesting articles, content, visuals and stories." />
            <meta property="og:description" content="KAAMKA.com - The No.1 Source for interesting articles, content, visuals and stories." />
            <meta property="og:image" content="https://kaamka.com/static/media/logo.181195da48df830a8e09.png" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
          </Helmet>
        <Header/>

        <div style={{display:'flex', borderRadius:10,flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:100, height:105, width:'100%'}}>
          <Button onClick={()=>navigate("/articles/work",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5, margin:5}}>Workplace</Button>
          <Button onClick={()=>navigate("/articles/humor",{state:{type:"Musician"}})} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5,margin:5}}>Humor</Button>
          <Button onClick={()=>navigate("/articles/movies",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5, margin:5}}>Movies</Button>
          <Button onClick={()=>navigate("/articles/sports",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5, margin:5}}>Sports</Button>
          <Button onClick={()=>navigate("/articles/tech",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5, margin:5}}>Technology</Button>
          <Button onClick={()=>navigate("/articles/music",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5, margin:5}}>Music</Button>

        </div>

        {/* <p style={{textAlign:'left', alignSelf:'flex-start', marginTop:20}}><b>Create Your AI Persona</b></p>

        <div style={{width:'100%', margin:20, justifyContent:'center',alignItems:'center', display:'flex', backgroundColor:'#dadada', height:70}}>

          <Button onClick={()=>navigate("/editProfile",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:35, width:'40%', borderRadius:5, margin:5}}>Create Your AI Persona</Button>

        </div> */}


        <p style={{textAlign:'left', alignSelf:'flex-start', marginTop:20}}><b>Trending</b></p>

        <div style={{ display: "grid", borderRadius:10, alignSelf:'flex-start',width:'100%', padding:20, backgroundColor:'black', margin:1 }}>
          <Button onClick={()=>navigate("/signup",{state:{type:"Space Traveller"}})} variant="contained" style={{float:'right', fontWeight:700, paddingLeft:30, paddingRight:30, fontSize:12, marginRight:20, backgroundColor:"black"}}>Get The Kaamka App</Button>
        </div>

        {/* <div style={{ display: "grid", alignSelf:'flex-start',width:'100%', backgroundColor:'black', margin:1, gridTemplateColumns: "repeat(8, 4fr)", gridGap: 5 }}>

        <p style={{backgroundColor:'#dadada80', borderRadius:10, padding:5,paddingLeft:20,paddingRight:20}}>Surfing</p>
          <p style={{backgroundColor:'#dadada80', borderRadius:10, padding:5,paddingLeft:20,paddingRight:20}}>Mike Tyson</p>
          <p style={{backgroundColor:'#dadada80', borderRadius:10, padding:5,paddingLeft:20,paddingRight:20}}>Beyonce</p>
          <p style={{backgroundColor:'#dadada80', borderRadius:10, padding:5,paddingLeft:20,paddingRight:20}}>Apple</p>
          <p style={{backgroundColor:'#dadada80', borderRadius:10, padding:5,paddingLeft:20,paddingRight:20}}>Nadal</p>
          <p style={{backgroundColor:'#dadada80', borderRadius:10, padding:5,paddingLeft:20,paddingRight:20}}>Virat Kohli</p>
          <p style={{backgroundColor:'#dadada80', borderRadius:10, padding:5,paddingLeft:20,paddingRight:20}}>Messi</p>
          <p style={{backgroundColor:'#dadada80', borderRadius:10, padding:5,paddingLeft:20,paddingRight:20}}>Nike</p>
          <p style={{backgroundColor:'#dadada80', borderRadius:10, padding:5,paddingLeft:20,paddingRight:20}}>Greece</p>

        </div> */}


        <div style={{ display: "grid", margin:40, gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>

          {articles.map((val:any, index:any) => {
              return (<div onClick={()=>navigate("/article/"+val.postID)} style={{backgroundColor:'white',height:'100%', display:'flex',flexDirection:'column'}}>
                <img style={{height:200}} src={val.image1}/>
                <h4 style={{textAlign:'center', fontSize:20, padding:20}}><b>{val.title}</b></h4>
                </div>)
            }
          )}

        </div>

     </div>
    

);


 }
 else{

  return (


    <div style={{margin:0,  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingLeft:20, paddingRight:20,paddingBottom:200, maxWidth:windowSize.innerWidth}} >

    <Header/>

        <div style={{display:'flex', borderRadius:10,flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:95, width:windowSize.innerWidth-10, paddingLeft:40,paddingRight:40}}>
          
          <Button onClick={()=>navigate("/articles/work",{state:{type:"Musician"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'70%', borderRadius:5,margin:5}}>Work</Button>
          <Button onClick={()=>navigate("/articles/humor",{state:{type:"Musician"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'70%', borderRadius:5,margin:5}}>Humor</Button>
          <Button onClick={()=>navigate("/articles/sports",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'70%', borderRadius:5, margin:5}}>Sports</Button>
          <Button onClick={()=>navigate("/articles/technology",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'70%', borderRadius:5, margin:5}}>Tech</Button>
          <Button onClick={()=>navigate("/articles/music",{state:{type:"Artist"}})} variant="contained" style={{textAlign:'center', padding:10,fontSize:10, backgroundColor:"black",color:'white', height:60, width:'70%', borderRadius:5, margin:5}}>Music</Button>

        </div>

    <div style={{margin:10,marginTop:25 }}>

          {articles.map((val:any, index:any) => {
              return (<div onClick={()=>navigate("/article/"+val.postID)} style={{backgroundColor:'white',width:'90%', margin:20, display:'flex',flexDirection:'column'}}>
                <img style={{height:200}} src={val.image1}/>
                <h4 style={{textAlign:'center', fontSize:20, padding:20}}>{val.title}</h4>
                </div>)
            }
          )}

        </div>

 </div>

);
 }

  
};

export default Home;
