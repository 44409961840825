import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import menu from "../assets/menu.png"
import { useNavigate } from "react-router-dom";
import {db, auth} from "../App"
import logo from "../assets/logo.png";
import Drawer from '@mui/material/Drawer';
import profile from '../assets/profile-user.png';

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}


interface IProps {
  screenName?: string
}

const Header: React.FC<IProps> =  ({ screenName  }: IProps) => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [user,set_user] = useState<string>("")
  const current = auth.currentUser

  console.log('currentxyz' ,current)

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {

    getUser()
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  }, []);


  function getUser(){

    let user = localStorage.getItem("user")

    if(user !== undefined && user !== null){
      set_user(user)
    }
    else{
      set_user("")
    }
  }

  function logOut(){
    console.log("logging out")
    auth.signOut()
    localStorage.clear()
    navigate("/")
    window.location.reload()
  }

  if(window.innerWidth > 650){

  return (
    <div className="col-md-12" style={{minWidth:windowSize.innerWidth, backgroundColor:'white', position:'absolute',top:0, padding:10, borderBottomColor:"#dadada",borderBottomWidth:1, borderBottomStyle:"solid"}} id="header">
      <Drawer style={{width:450}} open={open} onClose={toggleDrawer(false)}>
        <div style={{padding:80}}>
          <p>Kaamka.com</p>
          <a onClick={()=>logOut()}>Logout</a>
        </div>
      </Drawer>
      <div style={{flex:1, justifyContent:'center',alignItems:'center'}}>
      <img onClick={toggleDrawer(true)} src={menu} style={{height:22,width:22, float:'left', marginTop:14, marginLeft:20}}/>
      <img src={logo} onClick={()=>navigate("/")} style={{width:140, float:'left', marginLeft:20, borderRadius:20}}/>

      {/* <h1 style={{fontSize:28, marginLeft:20, marginTop:8, textAlign:'center', float:'left', color:'black', fontFamily:'Montserrat', fontWeight:700}}>Kaamka.com</h1> */}

      </div>

      {window.innerWidth > 650
      ?
      <div style={{flex:1, flexDirection:'row', justifyContent:'end',alignItems:'center', display:'flex'}}>
      <p style={{color:'black', float:'left',marginLeft:10,marginTop:18, marginRight:20, textAlign:'center'}}>Home</p>   
      {/* <p onClick={()=>navigate("/people")} style={{color:'black', float:'left',marginLeft:10,marginTop:18, marginRight:20, textAlign:'center'}}>People</p>   
      <p style={{color:'black', float:'left',marginLeft:10,marginTop:18, marginRight:20, textAlign:'center'}}>Skills</p>   
      <p onClick={()=>navigate("/rooms")} style={{color:'black', float:'left',marginLeft:10,marginTop:18, marginRight:20, textAlign:'center'}}>Rooms</p>   
      <p style={{color:'black', float:'left',marginLeft:10,marginTop:18, marginRight:20, textAlign:'center'}}>Articles</p>    */}

      {user !== ""
      ?
      <img src={profile} onClick={()=>navigate("/profile")} style={{width:25, marginRight:40}} />
      :
      <div>
      <Button onClick={()=>navigate("/signup",{state:{type:"Space Traveller"}})} variant="contained" style={{float:'right', fontWeight:700, paddingLeft:30, paddingRight:30, fontSize:12, marginRight:20, backgroundColor:"black"}}>Get The App</Button>
      {/* <Button onClick={()=>navigate("/signup",{state:{type:"Space Traveller"}})} variant="contained" style={{float:'right', fontWeight:700, paddingLeft:30, paddingRight:30, fontSize:12, marginRight:20, backgroundColor:"black"}}>Sign Up</Button> */}

      </div>
      } 
      </div>
      :
      <div style={{flex:1, flexDirection:'row', justifyContent:'end',alignItems:'center', display:'flex'}}>

      {/* {auth.currentUser !== null
      ?
      null
      :
      <Button variant="contained" onClick={()=>navigate("/login")} style={{float:'right', fontWeight:700, paddingLeft:30, paddingRight:30, fontSize:12, marginRight:20, backgroundColor:"#00bfff"}}>Log In</Button>
      }        */}
      </div>
      }


    </div>
  )
  }
  else{

    return (
      <div id="header">
      <Drawer style={{width:450}} open={open} onClose={toggleDrawer(false)}>
        <div style={{padding:80}}>
          <p>Kaamka.com</p>
        </div>
      </Drawer>
        <div style={{flex:1, flexDirection:'row',height:60, backgroundColor:'white', justifyContent:'flex-start',alignItems:'center', width:windowSize.innerWidth,display:'flex', marginTop:0}}>
        <img  onClick={toggleDrawer(true)} src={menu} style={{height:22,width:22, float:'left', marginLeft:20}}/>
        <img src={logo} onClick={()=>navigate("/")} style={{width:110, float:'left', marginLeft:20, borderRadius:20}}/>

        {user !== ""
        ?
        <img src={profile} onClick={()=>navigate("/profile")} style={{width:32,float:'right', alignSelf:'center', marginTop:0, position:'absolute',right:20}} />
        :
        <Button variant="contained" onClick={()=>navigate("/signup",{state:{type:"Space Traveller"}})} style={{float:'right',marginTop:0, alignSelf:'center', position:'absolute',right:20, marginBottom:0, fontWeight:700, paddingLeft:20, paddingRight:20, fontSize:10, backgroundColor:"black"}}>Get App</Button>
        }   
        </div>
  

        {/* <div style={{flex:1, flexDirection:'row', alignItems:'flex-end',justifyContent:'flex-end', display:'flex'}}>
  
        {user !== ""
        ?
        <img src={profile} onClick={()=>navigate("/profile")} style={{width:25, marginRight:40}} />
        :
        <Button variant="contained" onClick={()=>navigate("/login")} style={{float:'right',marginTop:0, marginBottom:0, fontWeight:700, paddingLeft:20, paddingRight:20, fontSize:10, backgroundColor:"#00bfff"}}>Log In</Button>
        }       
        </div> */}
        
  
  
      </div>

    )
  }
};

export default Header;
