import React, { useState, useEffect } from "react";
import { Button, TextField, Autocomplete, Chip } from "@mui/material";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import lofi8 from "../assets/lofi8.png"
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db, storage } from "../App";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

interface skills {
  id: number;
  name: string;
}


const userList: skills[] = [
  { id: 1, name: "Guitar" },
  { id: 2, name: "Drums" },
  { id: 3, name: "Bass" },
  { id: 4, name: "Keyboard" },
  { id: 5, name: "Production" },

];


const EditProfile: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [user,set_user] = useState<any>(null)
  const [bio,set_bio] = useState<any>(null)
  const [skills,set_skills] = useState<any>(null)
  const [location,set_location] = useState<any>(null)
  const [value, setValue] = React.useState<any>([]);
  const [image, set_image] = useState<any>(null)
  const [isImgSelected, set_isImgSelected] = useState<any>(false)
  const [profession,set_profession] = useState<any>(null)

  const navigate = useNavigate();

  useEffect(() => {

    //fetchPost();

    getProfile()
    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }


    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };


  },[]);

  function getUser(){

    let user = localStorage.getItem("user")

    if(user !== undefined && user !== null){
      set_user(user)
    }
    else{
      set_user("")
    }
  }


  async function saveProfile(){

    console.log('saveProfile', value)

    const userRef = doc(db, "users",user.userId);

      await updateDoc(userRef, {
        skills:value,
        location:location,
        bio:bio
      });

  }


  const onImageChange = (event:any) => {
    if (event.target.files && event.target.files[0]) {
      set_image(event.target.files[0]);
      set_isImgSelected(true)
    }
   }

   async function saveImage(){

    var randNum = String(Math.floor(Math.random()*9000) + 1000);
    var timeNow = new Date().getTime();
    var postId = timeNow + randNum;
  
    const imageRef = storageRef(storage, 'profile_images/' + postId + "_1");

    uploadBytes(imageRef, image)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then(async (url1) => {
            console.log('done upload')
            const userRef = doc(db, "users",user.userId);

            await updateDoc(userRef, {
              userpic:url1
            }).then(async()=>{
              const q = query(collection(db, "users"), where("email", "==", user.email));

              const querySnapshot = await getDocs(q);
      
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                localStorage.setItem("user", JSON.stringify(doc.data()))
              });
            })

            })
      .catch((error) => {
        alert(error.message);
      });

   })
  }


  async function getProfile(){

    let userL = localStorage.getItem('user');

    if(userL != null){
      console.log('udss',JSON.parse(userL));
      let usx = JSON.parse(userL)

      await set_user(JSON.parse(userL));
      await set_profession(usx.profession);

    const docRef = doc(db, "users",usx.userId);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      
      await set_user((docSnap.data()));
      

      if(docSnap.data().bio != null && docSnap.data().bio != undefined){
        console.log('got biox', docSnap.data().bio)
        await set_bio(docSnap.data().bio);
      }
      if(docSnap.data().skills!= null && docSnap.data().skills!= undefined){
        await setValue(docSnap.data().skills);
      }
      if(docSnap.data().location!= null && docSnap.data().location!= undefined){
        await set_location(docSnap.data().location);
      }

    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    }

  }




if(user!= null){
 if(window.innerWidth > 650){
  return (


    <div style={{margin:0,  backgroundImage:"url("+lofi8+")",backgroundSize:"cover",  flexDirection:'column', backgroundColor:'#35063e', zIndex:99999, minHeight:'100vh', backgroundRepeat:'repeat', paddingBottom:100, display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:200, paddingRight:200,paddingTop:40}} >

        <img src={logo} onClick={()=>navigate("/")} style={{height:40 ,borderRadius:20, margin:20, marginBottom:50}}/>

        {/* <p style={{marginTop:-40, marginBottom:40, fontStyle:'italic', color:'white'}}>Aap kya karte hain ?</p> */}

        <div style={{display:'flex',flexDirection:'row', height:240, width:'60%', justifyContent:'center',borderRadius:50, alignItems:'center'}}>

            <div style={{flex:1,borderBottomLeftRadius:10,borderTopLeftRadius:10, backgroundColor:"#280137", justifyContent:'center',alignItems:'center', display:'flex', height:'100%', margin:0}}>

            {user != null?
              // <img src={user.photoURL?user.photoURL:null} style={{height:90,width:90}}/>
              <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
              {isImgSelected===false?
                <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                {/* <p style={{fontSize:28,backgroundColor:"black",color:'white',padding:50,borderRadius:40}}>{(user.username).substring(0, 1)}</p> */}
                <img alt="preview image" src={user.userpic} style={{height:80,width:80}} />
                <label style={{color:'white'}}>
                <input style={{display:"none"}} type="file" onChange={onImageChange} className="filetype" />
                Select Profile Image
                </label>
                </div>
                :
                <div>
                <img alt="preview image" src={URL.createObjectURL(image)} style={{height:80,width:80}} />
                <Button onClick={()=>saveImage()} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', marginTop:20, height:40, width:'80%', borderRadius:20, fontSize:12}}>Save</Button>

                </div>
                }

              </div>
              :
              null}
              
            </div>

            <div style={{flex:1,borderBottomRightRadius:10,borderTopRightRadius:10, flexDirection:'column', backgroundColor:"#280137", height:'100%', margin:0,justifyContent:'center',alignItems:'center', display:'flex',}}>

                <h3 style={{color:'white'}}>{user.username}</h3>

                <h2 style={{textAlign:'center',color:'white'}}>{profession}</h2>

            </div>

        </div>

        <div style={{display:'flex',backgroundColor:"#280137", flexDirection:'column', marginTop:40, padding:50, width:'60%', paddingBottom:20, justifyContent:'center',borderRadius:10, alignItems:'center'}}>


                <p style={{color:'white'}}>About Me</p>

                <div style={{margin:5}}>
                <TextField placeholder="A little about you." value={bio} onChange={(event)=>set_bio(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:2}} />

                </div>

                <p style={{color:'white'}}>Skills</p>


                <div style={{margin:5}}>
                    <Autocomplete
                    value={user.skills != null?user.skills:value}
                      onChange={(event, newValue) => {
                        // console.log("setting val",newValue)
                        setValue(newValue);
                      }}
                      multiple
                      id="tags-filled"
                      options={userList.map((option) => option.name)}
                      freeSolo
                      renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Skills"
                          style={{backgroundColor:"white", width:400}}
                          placeholder="Search"
                        />
                      )}
                    />
                </div>


                <p style={{color:'white'}}>Location</p>

                <div style={{margin:5}}>
                <TextField placeholder="Your Pin Code" value={location} onChange={(event)=>set_location(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:2}} />

                </div>
                
                <Button onClick={()=>saveProfile()} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', marginTop:20, height:40, width:'80%', borderRadius:20, fontSize:12}}>Save</Button>

        </div>

        <div>
    
    </div>
    </div>

    

);


 }
 else{

  return (



    <div style={{margin:0,  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingLeft:20, paddingRight:20,paddingBottom:200, maxWidth:windowSize.innerWidth}} >

        <img src={logo} style={{height:40 ,borderRadius:20, margin:20, marginBottom:50}}/>

        <div style={{display:'flex',flexDirection:'row', height:240, width:'90%', justifyContent:'center',borderRadius:50, alignItems:'center'}}>

        <div style={{flex:1,borderBottomLeftRadius:10,borderTopLeftRadius:10, backgroundColor:"#280137", justifyContent:'center',alignItems:'center', display:'flex', height:'100%', margin:0}}>

          {user != null?
              <img src={user.photoURL?user.photoURL:null} style={{height:90,width:90}}/>
              :
              null}
              

            </div>

            <div style={{flex:1,borderBottomRightRadius:10,borderTopRightRadius:10, flexDirection:'column', backgroundColor:"#280137", height:'100%', margin:0,justifyContent:'center',alignItems:'center', display:'flex',}}>

                <h3 style={{color:'white'}}>{user.username}</h3>

                <h2 style={{textAlign:'center',color:'white'}}>{profession}</h2>

            </div>

        </div>

        <div style={{display:'flex', padding:40,backgroundColor:"#280137", marginBottom:100, flexDirection:'column', marginTop:40, width:'90%', justifyContent:'center',borderRadius:10, alignItems:'center'}}>


                <p style={{color:'white', marginTop:10,marginBottom:-5}}>About Me</p>

                <div style={{margin:5}}>
                <TextField placeholder="A little about you." onChange={(event)=>set_bio(event.target.value)} style={{width:'100%', maxWidth:'100%', backgroundColor:'white', borderRadius:2}} />

                </div>

                <p style={{color:'white', marginTop:10,marginBottom:-5}}>Skills </p>


                <div style={{margin:5}}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      multiple
                      id="tags-filled"
                      options={userList.map((option) => option.name)}
                      freeSolo
                      renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Skills"
                          style={{backgroundColor:"white", width:'100%'}}
                          placeholder="Search"
                        />
                      )}
                    />
                </div>


                <p style={{color:'white', marginTop:10,marginBottom:-5}}>Location </p>

                <div style={{margin:5}}>
                <TextField placeholder="Your location." onChange={(event)=>set_location(event.target.value)} style={{width:'100%', maxWidth:'100%', backgroundColor:'white', borderRadius:2}} />

                </div>
                
                <Button onClick={()=>saveProfile()} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', marginTop:20, height:40, width:'80%', borderRadius:20, fontSize:12}}>Save</Button>

      </div>
    </div>


);
 }
}
else{
  return null;
}

  
};

export default EditProfile;
